import {NgModule, Optional, SkipSelf} from "@angular/core";
import {AuthService} from "./services/auth.service";
import {SidenavService} from "./services/sidenav.service";

@NgModule(({
    providers: [
        AuthService,
        SidenavService
    ]
}))
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppMocule only !'
            )
        }
    }
}