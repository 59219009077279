import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SidenavService {

    private isSidenavOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isSidenavOpen$: Observable<boolean> = this.isSidenavOpenSubject.asObservable();

    constructor() {
    }

    toggleMenu() {
        const currentState = this.isSidenavOpenSubject.getValue();
        this.isSidenavOpenSubject.next(!currentState);
    }
}
