<div class="app">
    <app-header></app-header>
    <div class="remaining-page">
        <app-sidenav *ngIf="showSidenav" class="open"></app-sidenav>
        <main role="main" class="content-app">
            <router-outlet></router-outlet>
        </main>
    </div>
</div>

<app-loader *ngIf="loaderIsVisible" [message]="loaderMessage"></app-loader>

