import {Component, OnDestroy, OnInit} from '@angular/core';
import {SidenavService} from "./shared/services/sidenav.service";
import {Subject} from "rxjs";
import {AuthService} from "./core/services/auth.service";
import {Event, NavigationEnd, Router} from "@angular/router";
import {filter, takeUntil} from 'rxjs/operators';
import {LoaderService} from "./shared/services/loader.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    protected readonly LoaderService = LoaderService;

    isAuthenticated: boolean = false;
    userInfo: any;
    showSidenav: boolean = false;
    private unsubscribe$ = new Subject<void>(); // Manages subscriptions to avoid memory leaks.
    public loaderIsVisible = false;
    public loaderMessage = ""

    constructor(
        private router: Router,
        private authService: AuthService,
        private sidenavService: SidenavService,
        private loaderService: LoaderService,
    ) {
        // Subscribes to router events and updates the showSidenav property based on the current route.
        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe((event: NavigationEnd) => {
            const noSidenavRoutes = ['/access-error']; // List of routes where sidenav should not be displayed.
            this.showSidenav = !noSidenavRoutes.includes(event.urlAfterRedirects);
        });

        // Subscribes to sidenav service to update the sidenav width dynamically.
        this.sidenavService.isSidenavOpen$.pipe(
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe(isOpen => {
            this.updateWidthSideNav(isOpen);
        });

        // Subscribes to userInfo changes from AuthService.
        this.authService.userInfo.pipe(
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe((userInfo: any) => {
            this.userInfo = userInfo;
        });

        // Subscribe to loader service 
        this.loaderService.loaderIsVisible.pipe(
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe((loaderIsVisible: boolean) => {
            this.loaderIsVisible = loaderIsVisible;
        });

        this.loaderService.message.pipe(
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe((loaderMessage) => {
            this.loaderMessage = loaderMessage;
        });
    }

    async ngOnInit() {
        this.isAuthenticated = await this.authService.isAuthenticated(); // Checks if the user is authenticated.
        await this.authService.handleAuthentication(); // Handles authentication process.
        //this.userInfo = await this.authService.getUserInfo(); // Retrieves user information.
    }

    ngOnDestroy() {
        this.unsubscribe$.next(); // Triggers the completion of all observables.
        this.unsubscribe$.complete(); // Ensures no further subscriptions are taken after the component is destroyed.
    }

    private updateWidthSideNav(isOpen: boolean) {
        // Directly manipulates the DOM to adjust the width of the sidenav and the margin of the main content.
        const sidenav = document.querySelector('app-sidenav') as HTMLElement;
        if (sidenav.classList.contains('open')) {
            sidenav.classList.remove('open');
        } else {
            sidenav.classList.add('open');
        }
    }

}
