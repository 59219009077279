import {Component} from '@angular/core';
import {TranslocoPipe} from "@jsverse/transloco";

@Component({
  selector: 'app-access-error',
  templateUrl: './access-error.component.html',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  styleUrl: './access-error.component.scss'
})
export class AccessErrorComponent {

}
