import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private loaderIsVisibleSubject = new BehaviorSubject<boolean>(false);
    public loaderIsVisible = this.loaderIsVisibleSubject.asObservable();

    private messageSubject = new BehaviorSubject<string>("");
    public message = this.messageSubject.asObservable();


    constructor() {
    }

    showLoader() {
        this.loaderIsVisibleSubject.next(true)
    }

    hiddenLoader() {
        this.loaderIsVisibleSubject.next(false)
    }

    setMessage(message: string) {
        this.messageSubject.next(message);
    }
}
