import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccessErrorComponent} from "./shared/composants/errors/access-error/access-error.component";

const routes: Routes = [{
    path: '',
    redirectTo: `/home`,
    pathMatch: 'full'
}, {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
},
    {
        path: 'access-error', component: AccessErrorComponent
    },
    {
        path: 'meal-tray',
        loadChildren: () => import('./modules/meal-tray/meal-tray.module').then(m => m.MealTrayModule)
    },
    {
        path: '**',
        redirectTo: `/home`,
        pathMatch: 'full'
    }];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {


}
